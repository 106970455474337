.Performance {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .rigth-title {
      display: flex;
      align-items: center;
      gap: 12px;
      .click {
        display: flex;
        align-items: center;
        gap: 8px;
        .circle {
          width: 8px;
          height: 8px;
          background-color: #007bff;
          border-radius: 50%;
        }
      }
      .ant-dropdown-link {
        padding: 4px 8px;

        border-radius: 6px;
        border: 1px solid var(--shorten-primary-p-1, #e5f2ff);
        background: var(--shorten-primary-p-1, #e5f2ff);

        /* elevation/theme_light/e1 */
        box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
      }
    }
  }
}
