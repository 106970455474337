.numbers {
  display: flex;
  padding: 40px 80px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background: var(--neutral-n-1, #fff);
  .container {
    max-width: 1120px;
    .items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      width: 100%;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        .number {
          color: var(--blue-primary-p-8, #18496a);
          font-family: Noto Sans;
          font-size: 58px;
          font-style: normal;
          font-weight: 400;
          line-height: 108px; /* 166.154% */
          letter-spacing: -1.5px;
        }
        .text {
          color: var(--blue-primary-pp-5, #3498db);
          text-align: center;

          /* Heading / H4 - 34px - SB - 0.25px */
          font-family: Noto Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 44px; /* 183.333% */
          letter-spacing: 0.25px;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .container {
      max-width: 100%;
      .items{
        flex-direction: column;
        .item{
          .number {
            font-size: 48px;
            line-height: normal;

          } 
          .text{
            font-size: 20px;
          }
        }
      }
    }
  }
}
