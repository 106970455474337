.header {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 99;
}
.white-header {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 64px;
}
nav {
  // height: 40px;
  width: 100vw;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  //position: fixed;
  position: relative;
  z-index: 10;
  max-width: 1120px;
  margin: 0 auto;
}
.logo-header {
  padding: 1vh 1vw;
  text-align: center;
  cursor: pointer;
  span {
    color: var(--shorten-primary-p, #007bff);
    text-align: center;

    /* Heading / H4 - 34px - SB - 0.25px */
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 183.333% */
    letter-spacing: 0.25px;
    margin-left: 8px;
  }
}
.logo-header img {
  height: 48px;
  width: 48px;
}

/*Styling Links*/
.nav-links {
  display: flex;
  list-style: none;
  width: 80vw;
  padding: 0 0.7vw;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  gap: 20px;
  margin: 0;
}
.nav-links li a {
  color: #000;
  text-align: center;

  /* Sub / Subtitle 1 - 16px - SB - 0.15px */
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
  letter-spacing: 0.15px;
}
.nav-links li a:hover {
  color: #61dafb;
}
.nav-links li {
  position: relative;
}
.nav-links li a:hover::before {
  width: 80%;
}
.nav-links li:last-child {
  display: none;
}
.hamburger div {
  width: 30px;
  height: 3px;
  background: #000;
  margin: 5px;
  transition: all 0.3s ease;
}
.hamburger {
  display: none;
}
.right-header {
  display: flex;
  align-self: center;
  width: 20%;
  .info-user {
    display: flex;
    gap: 12px;
    align-items: center;
    a {
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: #262626;
      &.user-icon {
        white-space: nowrap;
      }
      &.ant-dropdown-trigger {
        color: #8C8C8C;
      }
    }
    img {
      width: 48px;
      height: 48px;
      padding: 14px;
      background-color: #007BFF;
      border-radius: 50%;
    }
  }
}
.button-login {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--shorten-primary-p, #007bff);
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  cursor: pointer;
  span {
    color: var(--neutral-n-2, #fafafa);
    text-align: center;

    /* Button / Button 2 - 16px - SB- 0.5px */
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}

.item-menu {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  p {
    margin: 0;
  }
}
@media screen and (max-width: 800px) {
  nav {
    position: absolute;
    width: 100%;
    z-index: 3;
  }
  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    z-index: 2;
    transition: all 0.7s ease;
  }
  .right-header {
    display: none;
  }
  .nav-links {
    position: fixed;
    right: 0;
    background: #131418;
    height: 100vh;
    width: 70%;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(50px at 90% -10%);
    transition: all 1s ease-out;
    pointer-events: none;
  }
  .nav-links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
    pointer-events: all;
  }
  .nav-links li {
    opacity: 0;
  }
  .nav-links li:nth-child(1) {
    transition: all 0.5s ease 0.2s;
  }
  .nav-links li:nth-child(2) {
    transition: all 0.5s ease 0.4s;
  }
  .nav-links li:nth-child(3) {
    transition: all 0.5s ease 0.6s;
  }
  .nav-links li:nth-child(4) {
    transition: all 0.5s ease 0.7s;
  }
  .nav-links li:nth-child(5) {
    transition: all 0.5s ease 0.8s;
  }
  .nav-links li:nth-child(6) {
    transition: all 0.5s ease 0.9s;
    margin: 0;
  }
  .nav-links li:nth-child(7) {
    transition: all 0.5s ease 1s;
    margin: 0;
  }
  .nav-links li:last-child {
    display: block;
  }
  li.fade {
    opacity: 1;
    a {
      color: #fff;
    }
  }
}
/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}
.toggle div {
  background: #fff;
}
