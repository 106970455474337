.OurCustomers {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
  gap: 40px;
  .title {
    color: var(--neutral-n-11, #1f1f1f);
    text-align: center;
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px; /* 133.333% */
    letter-spacing: 0.25px;
    text-transform: capitalize;
    strong {
      color: #007bff;
    }
  }
  .items {
    .swiper {
      padding: 40px 0;
    }
    .item {
      display: flex;
      padding: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      border-radius: 8px;
      border: 1px solid var(--neutral-n-6, #bfbfbf);
      background: var(--neutral-n-1, #fff);
      .description {
        color: var(--neutral-n-9, #434343);

        /* Body / Body 1 - 16px - R - 0.5px */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        letter-spacing: 0.55px;
      }
      .item-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        .name {
          color: var(--neutral-n-10, #262626);

          /* Sub / Subtitle 1 - 16px - SB - 0.15px */
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px; /* 162.5% */
          letter-spacing: 0.15px;
        }
        .job {
          color: var(--neutral-n-10, #262626);

          /* Body / Body 2 - 14px - R - 0.25px */
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.25px;
        }
      }
    }
  }

  .testimonial {
    width: 100%;
    height: 130vh;
    border: none;
    .container-detals-wall-of-love {
      background-color: transparent !important;
    }
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
    padding: 20px 16px;
    gap: 24px;
    .title {
      font-size: 24px;
      line-height: 44px;
    }
    .items {
      .swiper {
        padding: 0px 0px 40px 0px;
      }
      .item {
        padding: 12px 16px;
        gap: 12px;
        .item-icon {
          width: 120px;
          height: 26px;
          svg {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .description {
          font-size: 14px;
        }
        .item-footer {
          .name {
            font-size: 14px;
          }
          .job {
            font-size: 12px;
          }
        }
      }
    }
    .testimonial {
      height: 100vh;
    }
  }
}
