.ActiveShortenInfo {
  .title {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .text {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 12px;
    }
    .edit {
      position: absolute;
      right: 0;
      .ant-btn {
        display: flex;
        align-items: center;
        gap: 12px;
        height: 100%;
        padding: 8px 12px;
        border-radius: 12px;
        background: var(--shorten-primary-p, #007bff);

        /* elevation/theme_light/e1 */
        box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
      }
    }
    .sub-heading{
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 20px;
        .icon-copy{
          cursor: pointer;
        }
    }
    .heading{
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 400;
      a {
        color: #434343;
      }
    }
  }
  .box-total {
    display: flex;
    padding: 30px 0;
    gap: 12px;
    .item {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 12px;
      border: 1px solid var(--neutral-n-5, #d9d9d9);
      max-height: 78px;
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #ff6b6b;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .title {
          color: var(--neutral-n-8, #595959);
          text-align: center;

          /* Sub / Subtitle 2 - 14px - SB- 0.1px */
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.1px;
        }
        .number {
          color: var(--neutral-n-11, #1f1f1f);

          /* Heading / H5 - 24px - SB - 0px */
          font-family: Noto Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 34px; /* 141.667% */
        }
      }
    }
  }
}
