.hero{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title{
        font-family: Noto Sans;
    }
    .logo{
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}