.FAQs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1120px;
  margin: 0 auto;
  padding: 80px 0;
  gap: 40px;
  .title {
    color: var(--neutral-n-11, #1f1f1f);
    text-align: center;

    /* Heading /H3 - 48px - M - 0px */
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px; /* 133.333% */
    letter-spacing: 0.25px;
  }
  .content {
    width: 100%;
    .ant-space {
      width: 953px;
      gap: 16px !important;
      .ant-collapse {
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 20px;
            color: var(--neutral-n-10, #262626);

            /* Heading/H6 - 20px - M - 0.15px */
            font-family: Noto Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 150% */
            letter-spacing: 0.15px;
            .anticon {
              font-size: 24px;
            }
          }
          .ant-collapse-content {
            width: 100%;
            margin: 0 20px;
            .ant-collapse-content-box {
              margin: 0 10px;
              ul {
                width: 100%;
                margin: 0;
                li {
                  color: var(--neutral-n-9, #434343);

                  /* Body / Body 1 - 16px - R - 0.5px */
                  font-family: Noto Sans;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 26px; /* 162.5% */
                  letter-spacing: 0.55px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
      max-width: 100%;
      gap: 24px;
      margin: 0;
      padding: 40px 16px;
      .title{
        font-size: 24px;
        font-weight: 600;
      }
      .content{
        .ant-space{
          width: 100%;
          .ant-collapse {
            .ant-collapse-item {
              display: flex;
              flex-direction: column;
              gap: 20px;
              .ant-collapse-header {
                padding: 12px;
                font-size: 16px;
                width: 98%;
              }
              .ant-collapse-content {
                .ant-collapse-content-box {
                  ul{
                    li {
                       font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
}
