.TermsAndConditions {
  .header {
    position: absolute;
  }
  .content {
    display: flex;
    flex-direction: column;
    .container {
      max-width: 1120px;
      margin: 0 auto;
      .content-container {
        padding: 40px 0;
        p {
          margin: 0;
          color: var(--neutral-n-10, #262626);

          /* Body / Body 1 - 16px - R - 0.5px */
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px; /* 162.5% */
          letter-spacing: 0.55px;
        }
        display: flex;
        flex-direction: column;
        gap: 25px;
        .text{
          .title{
            font-weight: 600;

          }
        }
      }
    }
  }
}
