.LinkShort {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 1120px;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin: auto;
    padding: 0 16px;
  }
  p {
    margin: 0;
  }
  .title {
    display: flex;
    align-items: center;
    gap: 12px;
    > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    p {
      position: relative;
      display: flex;
      align-items: center;
      color: #000;
      font-family: Noto Sans;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 86px; /* 143.333% */
      letter-spacing: -1.5px;
      span {
        position: absolute;
        display: flex;
        align-items: center;
        left: 0px;
      }
    }
    .bg-text {
      background-color: #cce5ff;
      border-radius: 8px;
      padding: 0 20px 0 60px;
      @media screen and (max-width: 800px) {
        padding-right: 10px;
      }
    }
    .heart{
      position: relative;
      display: flex;
      padding-left: 12px;
      .icon-heart{
        position: absolute;
        transform:  translate(75%,-60%);
        @media screen and (max-width: 800px) {
          transform:  translate(100%,100%);
          right: -10px;
          width: 26px;
          height: 26px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      >div {
        gap: 0px;
      }
      p {
        font-size: 44px;
      }
    }
  }
  .center {
    color: #000;
    text-align: center;
    width: 601px;
    /* Body / Body 1 - 16px - R - 0.5px */
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.55px;
    @media screen and (max-width: 800px) {
      width: auto;
    }
  }
  .input {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .form-input {
      display: flex;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      gap: 4px;
      .ant-input {
        display: flex;
        width: 517px;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 20px;
        border-radius: 8px;
        border: 1px solid var(--neutral-n-5, #d9d9d9);
        background: var(--neutral-n-2, #fafafa);
      }
      .button-type {
        display: flex;
        align-items: center;
        .ant-btn {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 12px 16px;
          gap: 16px;
          border-radius: 8px;
          span {
            color: var(--neutral-n-2, #fafafa);
            text-align: center;

            /* Button / Button 2 - 16px - SB- 0.5px */
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.5px;
            text-transform: capitalize;
            @media screen and (max-width: 800px) {
                display: none;
            }
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
