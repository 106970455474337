.HeaderPrivacy {
  display: flex;
  width: 100%;
  height: 500px;
  background-color: #e5f2ff;
  .container {
    max-width: 1120px;
    margin: 0 auto;
    .header-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 25px;
      padding: 50px 0 50px 0;
      .title {
        color: var(--neutral-n-11, #1f1f1f);
        text-align: center;
        font-family: Noto Sans;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.25px;
      }
      .day {
        color: var(--neutral-n-10, #262626);
        text-align: center;
        font-family: Noto Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 160% */
        letter-spacing: 0.15px;
      }
    }
  }
}
