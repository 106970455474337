.card-link-short {
  display: flex;
  justify-content: center;
  max-width: 1120px;
  margin: 0 auto;
  padding: 74px 0 43px 0;

  .items {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    .item {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      padding: 12px 20px;
      gap: 20px;
      border-radius: 8px;
      border: 1px solid var(--neutral-n-6, #bfbfbf);
      background: var(--neutral-n-1, #fff);
      .item-header {
        display: flex;
        align-items: center;
        gap: 16px;
        .title {
          color: var(--neutral-n-10, #262626);
          /* Heading/H6 - 20px - M - 0.15px */
          font-family: Noto Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 150% */
          letter-spacing: 0.15px;
        }
        .item-icon {
          width: 49px;
          height: 49px;
        }
      }
      .description {
        color: var(--neutral-n-9, #434343);

        /* Body / Body 2 - 14px - R - 0.25px */
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.25px;
      }
    }
  }
  @media screen  and (max-width: 800px){
      max-width: 100%;
      padding: 43px 16px 43px 16px;
      .items{
        flex-direction: column;
        .item{
          width: 100%;
        }
      }
  }
}
