.TableClicks {
  .ant-table {
    background: transparent;
    p {
      margin: 0;
      color: #262626;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    .ant-pagination-prev {
      border-radius: 8px;
      .ant-pagination-item-link {
        border: none;
        background-color: #fff;
      }
    }
    .ant-pagination-item {
      background: transparent;
      border: none;
    }
    .ant-pagination-item-active {
      border-radius: 8px;
      background: var(--shorten-primary-p, #007bff);
      box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
      a {
        color: #fff;
      }
    }
  }
}
